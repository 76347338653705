import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Countdown from "react-countdown";
import reportWebVitals from './reportWebVitals';
import {DateTime, FixedOffsetZone, IANAZone} from 'luxon';

// Renderer callback with condition
const renderer = ({ total, days, hours, minutes, seconds}) => {
    // Render a
    let suffix;
    let td_str = "";

    suffix = total>0?"😊":(total<0?"😡":"")

    if (days>0) td_str += days + "D ";
    if (hours>0 && days <= 7) td_str += hours + "H ";
    if (minutes>0 && days===0) td_str += minutes + "M ";
    if (seconds>=0 && (days===0 && hours===0)) td_str += seconds + "S ";

    return (<span>{td_str}{suffix}</span>);
};


fetch('./cds.json')
    .then(function(res) {
        return res.json()
    })
    .then(function(json) {
        let root = document.getElementById("root");
// let cd_array = JSON.parse(json_str)["cds"];
        let cd_array = json["cds"];

        cd_array.forEach(function (cdinfo, i) {
            // let datetime = DateTime.fromISO(cdinfo["target"])
            let datetime;

            const cd_type = cdinfo["type"]
            if(cd_type==="relative") {
                let now = DateTime.now().plus(cdinfo["target"])
                if("years" in cdinfo["target"])
                    datetime = DateTime.local(now.year);
                else if("months" in cdinfo["target"])
                    datetime = DateTime.local(now.year, now.month);
                else if("days" in cdinfo["target"])
                    datetime = DateTime.local(now.year, now.month, now.day);
                // else if("hours" in cdinfo["target"])
                //     datetime = DateTime.local(now.year, now.month, now.day, now.hour);
            }
            else if(cd_type==="annual") {
                let now = DateTime.now()
                datetime = DateTime.local(now.year, cdinfo["target"].months, cdinfo["target"].days);
            }else{
                if ("timezone" in cdinfo) {
                    try {
                        datetime = DateTime.fromISO(cdinfo["target"], {zone: new IANAZone(cdinfo["timezone"])})
                        if (!datetime.isValid)
                            throw 'invalid DateTime';
                    }catch(error){
                        datetime = DateTime.fromISO(cdinfo["target"], {zone: FixedOffsetZone.parseSpecifier(cdinfo["timezone"])})
                    }
                }
                else
                    datetime = DateTime.fromISO(cdinfo["target"])
            }


            let topDiv = document.createElement('div')
            let titleDiv = document.createElement('div')
            titleDiv.innerText = `#${i + 1}: ${cdinfo["title"]}`
            titleDiv.className = "cd-title"
            topDiv.appendChild(titleDiv)
            let targetDiv = document.createElement('div')
            // targetDiv.innerText = `${datetime.toFormat('yy.MM.dd HH:mm')}`
            targetDiv.innerText = `${datetime.toFormat('yy.MM.dd HH:mm')}`
            targetDiv.className = "cd-target"
            topDiv.appendChild(targetDiv)

            let cdDiv = document.createElement('div')
            cdDiv.id = "cd" + i
            cdDiv.className = "cd"
            topDiv.appendChild(cdDiv)

            let foot = document.createElement('div')
            foot.className = "foot"
            topDiv.appendChild(foot)

            root.appendChild(topDiv)

            if(cd_type==="relative") {
                ReactDOM.render(
                    <Countdown date={new Date(datetime)}
                               renderer={renderer} overtime={true}
                               onComplete={function (_timeDelta) {
                                   window.location.reload();
                               }}
                    />,
                    document.getElementById("cd" + i)
                );
            } else{
                ReactDOM.render(
                    <Countdown date={new Date(datetime)}
                               renderer={renderer} overtime={true}
                    />,
                    document.getElementById("cd" + i)
                );
            }
        })
    });



reportWebVitals();